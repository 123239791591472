import { findChildDealerInfo } from '@/resources/dealer_groups/dealer_groups';
import { DwiteStorageType, registerLocalDwiteListener, registerSessionDwiteListener, StorageKey } from '@/utils/storage-utilities';
const DwiteComponentTagName = 'capital-one-dwite-store';
const buttonStackConfigFull = (dealerId) => ({
    coafDealerId: dealerId,
    isButtonBranded: true,
    isClientEnabled: true,
    isPrequalStandaloneEnabled: true,
    isDefaultCtaButton: true,
    isCheckAvailabilityButtonEnabled: true,
    isScheduleTestDriveButtonEnabled: true,
    isTradeInButtonEnabled: true
});
const buttonStackConfigPQ = (dealerId) => ({
    coafDealerId: dealerId,
    isButtonBranded: true,
    isClientEnabled: true,
    isPrequalStandaloneEnabled: true,
    isDefaultCtaButton: true
});
const initializeOnlyPQ = (dealerId, clientToken) => {
    window.C1SR = {
        clientConfigs: {
            [clientToken]: buttonStackConfigPQ(dealerId)
        }
    };
};
const initializeFullButtonStack = (dealer, leadsNavClientToken) => {
    // If current dealer in aggregated inventory, initialize button stack for all parent/child dealers
    if (dealer.isDealerGroup && dealer.childDealerIds) {
        const clientConfigs = {};
        clientConfigs[leadsNavClientToken] = buttonStackConfigFull(dealer.dealerIds);
        dealer.childDealerIds.forEach((dealerId) => {
            const childDealerLeadsNavClientToken = findChildDealerInfo(dealer.dealerIds, dealerId, 'leadsNavClientToken');
            if (childDealerLeadsNavClientToken) {
                clientConfigs[childDealerLeadsNavClientToken] = buttonStackConfigFull(dealerId);
            }
        });
        window.C1SR = {
            clientConfigs
        };
    }
    // Otherwise, initialize PQ for current dealer only
    else {
        window.C1SR = {
            clientConfigs: {
                [leadsNavClientToken]: buttonStackConfigFull(dealer.dealerIds)
            }
        };
    }
};
const injectLeadsNav = () => {
    // LeadsNav Loader
    document.head.append(Object.assign(document.createElement('script'), {
        src: window.ENV_DATA.dwiteLoaderUrl,
        async: true,
        charset: 'utf-8'
    }));
};
export const initializeLeadsNav = (enhancedShowroomEnabled, dealer, leadsNavClientToken) => {
    if (enhancedShowroomEnabled) {
        initializeFullButtonStack(dealer, leadsNavClientToken);
    }
    else {
        initializeOnlyPQ(dealer.dealerIds, leadsNavClientToken);
    }
    injectLeadsNav();
};
// Register a listener to see when leadsNav idpData is changed
export const registerLeadsNavIdpListener = (callback) => {
    const observer = new MutationObserver(function (mutations) {
        if (document.querySelector(DwiteComponentTagName)) {
            observer.disconnect();
            // The setTimeout is needed to execute on the next event cycle otherwise the DWITEStore.local/session is not available
            // Temporarily add a 500 ms delay while Yeng implements a queue to make sure the observer registers the callback if the
            // observer isn't ready
            setTimeout(() => {
                registerLocalDwiteListener(StorageKey.LeadsNavIDP, DwiteStorageType.GLOBAL, callback);
            }, 500);
        }
    });
    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
};
// Register a listener to see when leadsNav leadId is changed
export const registerLeadsNavLeadIdListener = (callback) => {
    const observer = new MutationObserver(function (mutations) {
        if (document.querySelector(DwiteComponentTagName)) {
            observer.disconnect();
            // The setTimeout is needed to execute on the next event cycle otherwise the DWITEStore.local/session is not available
            // Temporarily add a 500 ms delay while Yeng implements a queue to make sure the observer registers the callback if the
            // observer isn't ready
            setTimeout(() => {
                registerSessionDwiteListener(StorageKey.LeadsNavLeadId, DwiteStorageType.DOMAIN, callback);
            }, 500);
        }
    });
    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
};
